import React from "react"
import ArticlePage from "../components/Content/ArticlePage"

import "../assets/scss/App.scss"


const PrivacyPage = ((props) => {
    return (
        <React.Fragment>
            <ArticlePage className={'all-text'} slug={'privacy-notice'} />
        </React.Fragment>
    )
})

export default PrivacyPage;
